export const CommodityTypes = {
  handphone: "handphone",
  gadget: "gadget",
  furniture: "furniture",
  peralatan_rumah_tangga: "peralatan_rumah_tangga",
  televisi: "televisi",
  komputer_atau_laptop: "komputer_atau_laptop",
  pakaian: "pakaian",
  renovasi: "renovasi",
  aksesoris_mobil: "aksesoris_mobil",
  kamera: "kamera",
  console: "console",
  jam: "jam",
  kacamata: "kacamata",
  perlengkapan_bayi: "perlengkapan_bayi"
};

export const CommodityName = [
  { key: "Mobile Phones", name: "Handphone" },
  { key: "Computers or Laptops", name: "Komputer atau Laptop" },
  { key: "Televisions", name: "Televisi" },
  { key: "Home Appliances", name: "Peralatan Rumah Tangga" },
  { key: "Furniture", name: "Furnitur" },
  { key: "Gadgets", name: "Gadget" },
  { key: "Camera", name: "Kamera" },
  { key: "Car Accessories", name: "Aksesoris Mobil" },
  { key: "Home Appliances", name: "Bahan Bangunan dan Perbaikan Rumah" }
];

export const CreditCardPromo = {
  grocery: "Grocery",
  dining: "Dining",
  fashion: "Fashion"
};
