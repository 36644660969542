const SliderStyle = () => {
  return (
    <style jsx global>{`
      @media only screen and (max-width: 600px) {
        .flickity-page-dots {
          bottom: 0px;
        }
        .flickity-page-dots .dot {
          width: 5px;
          height: 5px;
          margin: 0 4px;
        }
      }
    `}</style>
  );
};

export default SliderStyle;
