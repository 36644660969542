type SeparatorProps = {
  width?: string;
  color?: string;
  height?: string;
};

export const Separator = ({ width, color, height }: SeparatorProps) => {
  return (
    <div className="separator-wrapper">
      <div className="separator"></div>
      <style jsx>{`
        .separator-wrapper {
          display: flex;
          max-width: 100%;
          justify-content: center;
        }
        .separator {
          width: ${width ? width : "80px"};
          border-bottom: solid ${height ? height : "2px"}
            ${color ? color : "var(--color-red)"};
          border-top: solid ${height ? height : "2px"}
            ${color ? color : "var(--color-red)"};
          margin: 16px 0px;
        }
      `}</style>
    </div>
  );
};
