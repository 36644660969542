import React, { useState, useEffect } from "react";
import { DataCalculator2023 } from "../data/DataCalculator";
import "lazysizes";
import step1 from "../../public/static/images/png/loan/step_1.png";
import step2 from "../../public/static/images/png/loan/step_2.png";
import step3 from "../../public/static/images/png/loan/step_3.png";
import promoIcon from "../../public/static/images/png/promo_outline.png";
import reminderIcon from "../../public/static/images/png/loan/reminder-icon.png";
import Modal from "./UserInterface/Modal";
import { SliderCommodity } from "./UserInterface/SliderCommodity";
import UseWindowSize from "./UseWindowSize";
import LeadsForm from "./LeadsForm";
import {
  formatRupiah,
  reformatRupiah,
  moneyFormat,
} from "../utils/moneyFormat";
import { GACustomEvent } from "src/utils/analytics";
import { Separator } from "./UserInterface/Separator";

const Calculator = ({ asPath }) => {
  const MonthlyCalculation = ({ valuecredit, valuetenor }) => {
    return (
      <div className="monthly-calculation-container">
        <p className="title">Cicilan bulanan kamu sebesar</p>
        <p className="cost">{formatRupiah(valuecredit)}*</p>
        <p className="subtitle">
          Selama <strong>{valuetenor} bulan </strong>
          dan sudah termasuk biaya bulanan.
        </p>
        <style jsx>{`
          .monthly-calculation-container {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .monthly-calculation-container p {
            color: var(--color-white);
            font-size: 18px;
            font-weight: 400;
            margin: 0px 36px;
            text-align: center;
          }
          .monthly-calculation-container .title {
            font-weight: bold;
            font-size: 21px;
          }
          .monthly-calculation-container .subtitle {
            display: none;
          }
          .monthly-calculation-container .italic {
            font-style: italic;
            margin: 16px;
            width: 215px;
          }
          .monthly-calculation-container .cost {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 64px;
            width: 352px;
            border-radius: 28.5px;
            background-color: #fff;
            color: var(--color-green);
            font-size: 36px;
            font-weight: 700;
            margin: 16px;
          }
          @media only screen and (max-width: 600px) {
            .monthly-calculation-container {
              width: 100%;
            }
            .monthly-calculation-container .title {
              font-size: 18px;
              margin: 17px 10px;
            }
            .monthly-calculation-container .subtitle {
              font-size: 16px;
              margin: 16px 16px 8px;
              padding: 0px 12px;
              display: block;
            }
            .monthly-calculation-container .cost {
              font-size: 24px;
              margin: 0px;
              max-width: 90%;
              max-height: 46px;
            }
          }
        `}</style>
      </div>
    );
  };

  const CalculatorInput = ({ data }) => {
    const [price, setPrice] = useState(15000000);
    const [bunga, setBunga] = useState(1.5);
    const [tenor, setTenor] = useState(12);
    const [downPayment, setDownPayment] = useState(1500000);
    const [valueInsurance, setValueInsurance] = useState(0);
    const [valueCredit, setValueCredit] = useState(0);
    const [priceWarning, setPriceWarning] = useState(false);
    const [dpWarning, setDpWarning] = useState(false);
    const [popUp, setPopUp] = useState(false);
    const [proteksi, setProteksi] = useState(false);
    const [proteksiChoose, setProteksiChoose] = useState(
      "Menggunakan Proteksi Aman"
    );
    const [sliderDP, setsliderDP] = useState(downPayment);
    const [errorMsg, setErrorMsg] = useState({
      price: "",
      tenor: "",
      bunga: "",
      downPayment: "",
    });
    const [sliderTenor, setSliderTenor] = useState({
      item: data[2].tenor,
      min: 3,
      max: 15,
    });
    const creditValueRoundUp = Math.ceil(valueCredit / 100) * 100;
    useEffect(() => {
      if (proteksi === true) {
        HandleInsurance();
      } else if (proteksi === false) {
        setValueInsurance(0);
      }
      setsliderDP(downPayment);
      setValueCredit(
        Math.ceil(
          ((price - downPayment) * tenor * (bunga / 100) +
            (price - downPayment)) /
            tenor +
            valueInsurance
        )
      );
    }, [
      price,
      tenor,
      downPayment,
      valueCredit,
      sliderDP,
      valueInsurance,
      bunga,
    ]);
    const path = asPath ? " - " + asPath.replace(/^\/+/, "") : "";
    const value_dump = {
      proteksi: proteksiChoose,
      commodity: path,
      price,
      tenor,
      sliderDP,
      downPayment,
      creditValueRoundUp,
    };

    const HandlePrice = (e) => {
      const valuePrice =
        e.target.value.length < 1
          ? e.target.value
          : reformatRupiah(e.target.value);
      if (valuePrice >= data[0].minPrice && valuePrice <= data[0].maxPrice) {
        setPriceWarning(false);
        setErrorMsg({ ...errorMsg, price: "" });
      } else {
        if (valuePrice > data[0].maxPrice) {
          setErrorMsg({ ...errorMsg, price: "Harga maksimal Rp30.000.000" });
        } else {
          setErrorMsg({ ...errorMsg, price: "Harga minimal Rp500.000" });
        }
      }
      if (valuePrice < downPayment) {
        const maxDP = valuePrice * (90 / 100);
        if (valuePrice > 600000) {
          setDownPayment(maxDP);
        } else {
          setDownPayment(500000);
        }
      }else setPrice(valuePrice);
    };
    const HandleInsurance = () => {
      setValueInsurance((0.75 / 100) * tenor * (price - downPayment));
    };

    const HandleTenor = (e) => {
      const valueTenor = e.target.value;
      let max = bunga == 0 ? 9 : data[2].maxPrice;
      let min = bunga == 0 ? 6 : data[2].minPrice;

      setTenor(valueTenor);

      if (valueTenor <= max && valueTenor >= min) {
        setValueCredit(
          Math.ceil(
            (price -
              downPayment +
              (price - downPayment) * data.ir * e.target.value) /
              e.target.value
          )
        );
        setErrorMsg({ ...errorMsg, tenor: "" });
      } else {
        if (valueTenor < min) {
          setErrorMsg({
            ...errorMsg,
            tenor: `Tenor minimal ${min} bulan`,
          });
        } else if (valueTenor > max) {
          setErrorMsg({
            ...errorMsg,
            tenor: `Tenor maksimal ${max} bulan`,
          });
        }
      }
    };

    const HandleBunga = (e) => {
      const validateInput = (s) => {
        var rgx = /^[0-9]*\.?[0-9]*$/;
        return s.match(rgx);
      };
      const valueBunga = e.target.value;

      if (valueBunga <= 3 && valueBunga >= 0) {
        if (valueBunga == 0) {
          setSliderTenor({ ...sliderTenor, item: [6, 9], min: 6, max: 9 });
          setTenor(6);
        } else {
          setSliderTenor({
            ...sliderTenor,
            item: [3, 6, 9, 12, 15],
            min: 3,
            max: 15,
          });
        }
        if (valueBunga !== "." && validateInput(valueBunga)) {
          setBunga(valueBunga);
        }
      }
    };
    const HandleDP = (e) => {
      const valueDownPayment =
        e.target.value.length < 1
          ? e.target.value
          : reformatRupiah(e.target.value);
      if (valueDownPayment < price) {
        const maxDP = price * (90 / 100);
        if (
          valueDownPayment <= data[3].maxPrice &&
          valueDownPayment >= data[3].minPrice
        ) {
          setErrorMsg({ ...errorMsg, downPayment: "" });
        } else {
          if (valueDownPayment < data[3].minPrice) {
            setErrorMsg({
              ...errorMsg,
              downPayment: "Uang Muka minimal Rp500.000",
            });
          } else {
            setErrorMsg({
              ...errorMsg,
              downPayment: "Uang Muka maksimal 90% dari harga barang",
            });
          }
        }
        if (valueDownPayment > maxDP) {
          setDownPayment(maxDP);
        } else setDownPayment(valueDownPayment);
      }
    };

    const sumbitCalculator = () => {
      GACustomEvent("store_purchase_detail_find_out_more", "click");
      setPopUp(true);
    };
    const closeModal = () => {
      setPopUp(false);
    };
    const handleClickProteksi = () => {
      setProteksi(true);
      if (proteksi === false) {
        setProteksiChoose("Menggunakan Proteksi");
        setProteksi(true);
        HandleInsurance();
      } else {
        setProteksiChoose("Tidak Menggunakan Proteksi");
        setProteksi(false);
        setValueInsurance(0);
      }
    };
    return (
      <div className="calculation-type-container">
        <div className="commodity-component-container">
          <div className="commodity-left-component">
            <div className="input-slider-wrapper">
              {DataCalculator2023.map((data, key) => {
                return (
                  <div className="input-slider-container" key={key}>
                    <div
                      className={
                        priceWarning
                          ? "input-slider-top warning"
                          : "input-slider-top"
                      }
                    >
                      <div>{data.name}</div>
                      <div className="user-input-right">
                        <p style={{ margin: "0" }}>{data.currency && "Rp"}</p>
                        <input
                          name="price"
                          type={data.inputType}
                          value={
                            data.currency
                              ? formatRupiah(eval(data.value), true)
                              : eval(data.value)
                          }
                          onChange={eval(data.handler)}
                          maxLength={10}
                        />
                        <p style={{ margin: "0", marginLeft: "8px" }}>
                          {data.type === "percentage" && "%"}
                          {data.type === "month" && "Bulan"}
                        </p>
                      </div>
                    </div>
                    <div className="input-text-warning">
                      {errorMsg[data.value]}
                    </div>
                    <div className="input-slider-bot">
                      <input
                        type="range"
                        name="price"
                        min={
                          data.value === "tenor"
                            ? sliderTenor.min
                            : data.minPrice
                        }
                        max={
                          data.value === "tenor"
                            ? sliderTenor.max
                            : data.maxPrice
                        }
                        step={data.step}
                        value={eval(data.value)}
                        onChange={eval(data.handler)}
                      />
                    </div>
                    <div className="input-range-point">
                      {(data.value === "tenor"
                        ? sliderTenor.item
                        : data.tenor
                      ).map((item, key) => {
                        return (
                          <div key={key}>
                            {data.currency ? formatRupiah(item) : item}
                            {data.value === "bunga" && "%"}
                          </div>
                        );
                      })}
                    </div>
                    {data.value === "bunga" && (
                      <div className="promo-zero-banner">
                        <div>
                          <img src={promoIcon}></img>
                        </div>
                        <p>
                          Promo 0% hanya untuk tenor 6 dan 9 bulan serta berlaku
                          jika cicilan selalu dibayar tepat waktu.
                        </p>
                      </div>
                    )}
                    {key !== DataCalculator2023.length - 1 && (
                      <Separator
                        height="1px"
                        color="#D1D1D1"
                        width="100%"
                      ></Separator>
                    )}
                  </div>
                );
              })}
              <div className="commodity-protection">
                <div>
                  <p className="text-proteksi">Proteksi</p>
                  <div>
                    <label className="switch">
                      <input type="checkbox" checked={proteksi} readOnly />
                      <span
                        className="switch-slider round"
                        value={proteksi}
                        onClick={handleClickProteksi}
                      />
                    </label>
                  </div>
                </div>
                <p className="protection-description">
                  Kami memberikan kenyamanan kamu dalam berbelanja dengan
                  proteksi seperti <b>AMAN</b>, <b>SANTAI</b>, dan <b>EASYCOVER</b>.
                </p>
              </div>
            </div>
          </div>
          <div className="commodity-right-component">
            <div className="credit-value-container">
              <MonthlyCalculation
                valuecredit={creditValueRoundUp}
                valuetenor={tenor}
              />
              <div className="monthly-calculation-text">
                <ul>
                  <li>Cicilan sudah termasuk biaya bulanan.</li>
                  <li>
                    Cicilan jatuh tempo pada tanggal yang sama dengan tanggal
                    penandatanganan Kontrak Pembiayaan di bulan berikutnya dan
                    seterusnya.
                  </li>
                  <li>
                    Nilai cicilan bulananmu yang sesungguhnya bisa berbeda dari
                    hasil simulasi ini.
                  </li>
                </ul>
              </div>
              <div className="reminder-cicilan-card">
                <img
                  data-src={reminderIcon}
                  src={reminderIcon}
                  alt="step-one"
                  className="lazyload"
                />
                <p>
                  Selalu bayar tepat waktu biar JADI BISA bebas dari cicilan
                  terakhir.
                </p>
              </div>
            </div>
            <div className="commodity-pengajuan-cicilan">
              <div>
                <p className="text-pengajuan-cicilan">
                  3 Langkah Mudah Proses Pengajuan Cicilan
                </p>
              </div>
              <div className="cara-pengajuan-container">
                <div className="cara-pengajuan">
                  <img
                    data-src={step1}
                    src={step1}
                    alt="step-one"
                    className="lazyload"
                  />
                  <div>
                    <p className="bold">Pengajuan Instan</p>
                    <p>Proses pengajuan cepat hanya sekitar 3 menit.</p>
                  </div>
                </div>
                <div className="cara-pengajuan">
                  <img
                    data-src={step2}
                    src={step2}
                    alt="step-two"
                    className="lazyload"
                  />
                  <div>
                    <p className="bold">Anti Ribet</p>
                    <p>Cuma perlu siapin KTP & Foto Selfie</p>
                  </div>
                </div>
                <div className="cara-pengajuan">
                  <img
                    data-src={step3}
                    src={step3}
                    alt="step-three"
                    className="lazyload"
                  />
                  <div>
                    <p className="bold">Pengajuan di Toko</p>
                    <p>Proses pengajuan di toko terdekat</p>
                  </div>
                </div>
              </div>
              <div className="ajukan-button-container">
                <button className="ajukan-button" onClick={sumbitCalculator}>
                  Ajukan Sekarang
                </button>
              </div>
            </div>
          </div>
        </div>
        {popUp ? (
          <Modal close={closeModal}>
            <LeadsForm
              description="Halo! Kami dengan senang hati akan membantu Anda mengajukan aplikasi kredit barang impian Anda! Isi data berikut dan kami akan segera menghubungi Anda."
              title="Formulir Pengajuan"
              valueDump={value_dump}
            ></LeadsForm>
          </Modal>
        ) : (
          ""
        )}
        <style jsx>{`
          .user-input-right {
            display: flex;
            width: 160px;
            border: solid 1px #d1d1d1;
            padding: 4px 8px;
            justify-content: space-between;
            border-radius: 8px;
          }
          .promo-zero-banner {
            display: flex;
            align-items: center;
            padding: 8px;
            background-color: #e7f6e4;
            border-radius: 8px;
            margin-top: 8px;
          }
          .promo-zero-banner p {
            margin: 0 10px;
            font-size: 12px;
            color: #14810a;
            font-weight: bold;
          }
          .error-msg {
            color: var(--color-red);
            margin: 0px 0 5px 0;
          }
          input[type="range"] {
            -webkit-appearance: none;
            width: 100%;
            height: 15px;
            border-radius: 5px;
            background: #d3d3d3;
            outline: none;
          }
          .input-slider-wrapper {
            box-shadow: 0 3px 10px 0 var(--color-white-dark);
            border-radius: 11.87px;
            padding: 16px 16px;
            display: flex;
            margin-bottom: 16px;
            flex-direction: column;
            justify-content: space-around;
          }
          .input-slider-container {
            margin: 16px 0;
          }
          .input-slider-container .input-slider-top {
            display: flex;
            margin: 10px 0px;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
          }
          .input-slider-container .input-slider-top div {
            font-size: 18px;
          }
          .input-slider-container .input-slider-top div input,
          .input-slider-container .input-slider-top div select {
            font-size: 18px;
            text-shadow: 0 0 0 var(--color-gray);
            text-align: right;
            width: 100%;
            max-width: 160px;
            color: var(--color-green);
            border: none;
          }
          .input-slider-container .input-slider-top div select {
            text-align-last: center;
          }
          .input-slider-container .input-slider-bot input {
            width: 100%;
          }
          .input-slider-container .input-range-point {
            display: flex;
            justify-content: space-between;
          }
          .input-slider-container .input-range-point div {
            font-size: 11px;
            color: #a9a9a9;
          }
          .input-slider-container .input-text-warning {
            font-size: 12px;
            text-align: right;
            color: var(--color-red);
            height: 20.4px;
          }
          ::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: var(--color-red);
            cursor: pointer;
          }
          ::-moz-range-thumb {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: var(--color-red);
            cursor: pointer;
          }
          /*----------slider----------*/
          .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
          }
          .switch input {
            opacity: 0;
            width: 0;
            height: 0;
          }
          .switch input:checked + .switch-slider {
            background-color: var(--color-red);
          }
          .switch input:checked + .switch-slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
          }
          .switch-slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }
          .switch-slider:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: var(--color-white);
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }
          /* Rounded sliders */
          .switch-slider.round {
            border-radius: 34px;
          }
          .switch-slider.round:before {
            border-radius: 50%;
          }
          @media only screen and (max-width: 600px) {
            .input-slider-wrapper {
              margin: 16px;
            }
          }

          .commodity-component-container {
            display: flex;
            justify-content: space-between;
          }

          .commodity-component-container .commodity-left-component {
            width: 416px;
            margin-right: 10px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
          }
          .commodity-component-container
            .commodity-left-component
            .commodity-protection {
            background-color: var(--color-white);
            border-radius: 16px;
            border: solid 1px var(--color-white-dark);
            padding: 16px 24px;
            margin-top: 16px;
          }
          .commodity-component-container
            .commodity-left-component
            .commodity-protection
            p {
            font-size: 21px;
            font-weight: bold;
            margin: 0px;
          }
          .commodity-component-container
            .commodity-left-component
            .commodity-protection
            div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 14px;
          }
          .commodity-component-container
            .commodity-left-component
            .commodity-protection
            .protection-description {
            font-size: 14px;
            text-align: left;
            font-weight: normal;
          }
          .commodity-component-container .commodity-right-component {
            width: 416px;
          }
          .commodity-component-container .credit-value-container {
            background-color: #666666;
            display: flex;
            flex-direction: column;
            border-radius: 16px;
            padding: 32px 0px;
            box-shadow: 0 3px 10px 0 var(--color-white-dark);
            align-items: center;
          }
          .ajukan-button-container {
            justify-content: center;
            display: flex;
            margin: 50px 0px 5px;
          }
          .commodity-component-container
            .commodity-right-component
            .commodity-pengajuan-cicilan
            .ajukan-button {
            height: 44px;
            width: 336px;
            font-size: 14px;
            color: var(--color-red);
            font-weight: bold;
            background-color: var(--color-white);
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            border-color: var(--color-red);
            border-style: solid;
            border-radius: 28.5px;
          }
          .commodity-component-container
            .commodity-right-component
            .commodity-pengajuan-cicilan
            .ajukan-button:hover {
            border-color: var(--color-white);
            color: var(--color-white);
            background-color: var(--color-red);
            cursor: pointer;
          }
          .monthly-calculation-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            color: var(--color-white);
            margin: 10px 32px;
          }
          .monthly-calculation-text ul {
            font-size: 12px;
            text-align: left;
          }
          .monthly-calculation-text li {
            margin-left: 20px;
            list-style-type: disc;
          }
          .reminder-cicilan-card {
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: 48px;
            width: 337px;
            border-radius: 12px;
            background-color: #e5f3fb;
            margin-top: 12px;
            padding: 8px;
          }
          .reminder-cicilan-card p {
            font-size: 14px;
            font-weight: 600;
            color: #0077c8;
          }
          .reminder-cicilan-card img {
            width: 34px;
            height: 34px;
            margin-right: 10px;
          }
          .commodity-pengajuan-cicilan {
            background-color: var(--color-white);
            border-radius: 16px;
            box-shadow: 0 3px 10px 0 var(--color-white-dark);
            padding: 16px 24px;
            margin: 16px 0px 0px;
            text-align: center;
          }
          .cara-pengajuan-container {
            margin: 24px 0px;
          }
          .text-pengajuan-cicilan {
            font-size: 26px;
            font-weight: 900;
            padding: 0px 26px;
            margin: 10px 0px;
          }
          .cara-pengajuan-container .cara-pengajuan {
            display: flex;
            justify-content: flex-start;
            margin: 16px 0px;
          }
          .cara-pengajuan-container .cara-pengajuan img {
            width: 64px;
            height: 64px;
            margin: 10px 36px 10px 10px;
          }
          .cara-pengajuan-container .cara-pengajuan p {
            text-align: left;
            width: 100%;
            margin: 0px;
            font-size: 18px;
          }
          .cara-pengajuan-container .cara-pengajuan div {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .cara-pengajuan-container .cara-pengajuan .bold {
            font-size: 22px;
            font-weight: 900;
          }

          @media only screen and (max-width: 600px) {
            .input-slider-wrapper {
              margin: 16px;
            }
            .text-proteksi {
              display: flex;
              align-items: center;
            }
            .commodity-component-container {
              flex-direction: column;
              width: 100%;
            }
            .commodity-component-container .commodity-left-component {
              width: 100%;
              margin: 0px;
            }
            .commodity-component-container
              .commodity-left-component
              .commodity-protection {
              padding: 0px;
              margin: 16px;
            }
            .commodity-component-container
              .commodity-left-component
              .commodity-protection
              div {
              padding: 7px 16px;
              margin: 0px;
            }
            .commodity-component-container
              .commodity-left-component
              .commodity-protection
              .protection-description {
              display: none;
            }
            .commodity-component-container .commodity-right-component {
              width: 100%;
            }
            .commodity-component-container
              .commodity-right-component
              .credit-value-container {
              margin: 16px;
              padding: 0px 0 32px 0;
            }
            .reminder-cicilan-card {
              width: 85%;
              max-height: 38px;
            }
            .reminder-cicilan-card p {
              font-size: 14px;
            }
            .commodity-component-container
              .commodity-right-component
              .commodity-pengajuan-cicilan {
              margin: 16px;
            }
            .text-pengajuan-cicilan {
              font-size: 18px;
            }
            .ajukan-button-container {
              margin: 10px 0px 5px;
            }
            .commodity-pengajuan-cicilan .ajukan-button {
              font-size: 12px;
            }
            .cara-pengajuan-container .cara-pengajuan img {
              max-width: 40px;
              max-height: 40px;
            }
            .cara-pengajuan-container .cara-pengajuan div .bold {
              font-size: 16px;
              text-align: left;
            }
            .cara-pengajuan-container .cara-pengajuan div p {
              font-size: 12px;
              text-align: left;
            }
          }
        `}</style>
      </div>
    );
  };

  return (
    <>
      <div className="calculator-wrapper">
        <div className="calculator-container">
          <h1>Simulasi Perhitungan Cicilan</h1>
          <p>
            Perhitungan ini bersifat simulasi dan bertujuan sebagai ilustrasi.
          </p>
          <div className="calculator-calculation-container">
            <CalculatorInput data={DataCalculator2023} />
          </div>
        </div>
      </div>
      <style jsx>{`
        .calculator-wrapper {
          display: flex;
          justify-content: center;
          padding: 16px;
        }
        .calculator-container {
          width: 100%;
          max-width: 836px;
          padding: 15px 0px 0px;
        }
        .calculator-container p {
          text-align: center;
          font-size: 21px;
          font-weight: 400;
          margin: 0px 0px 40px;
        }
        .calculator-container h1 {
          text-align: center;
          font-size: 34px;
        }
        .calculator-commodity-slider {
          display: flex;
          justify-content: space-around;
        }
        .calculator-commodity-container-desktop {
          display: none;
        }
        @media only screen and (max-width: 600px) {
          .calculator-container h1 {
            margin-top: 20px;
            font-size: 18px;
          }
          .calculator-container p {
            font-size: 12px;
            padding: 0px 75px;
            margin: 10px 0px;
          }
          button {
            display: none;
          }
          .calculator-wrapper {
            padding: 0px;
          }
        }
        @media only screen and (min-width: 600px) {
          .calculator-commodity-slider {
            display: none;
          }
          .calculator-commodity-container-desktop {
            display: flex;
            align-items: self-end;
            margin: 28px 0px;
          }
        }
      `}</style>
    </>
  );
};

export default Calculator;
