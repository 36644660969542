import { CommodityTypes } from "./DataTypes";
import ImgSmartphone from "../../public/static/images/svg/komoditi-smartphone.svg";
import ImgPCLaptop from "../../public/static/images/svg/komoditi-laptopkomputer.svg";
import ImgCamera from "../../public/static/images/svg/komoditi-kamera.svg";
import ImgTelevisi from "../../public/static/images/svg/komoditi-televisi.svg";
import ImgFurniture from "../../public/static/images/svg/komoditi-furnitur.svg";
import ImgGadget from "../../public/static/images/svg/komoditi-gadget.svg";

export const Data = [
  {
    name: "Handphone",
    img: ImgSmartphone,
    type: CommodityTypes.handphone,
    data: {
      insurance: false,
      minPrice: 825000,
      maxPrice: 14250000,
      specialPrice: 5600000,
      ir: 0.0249,
      tenor: [6, 9],
      minTenor: 6,
      maxTenor: 9,
      minDP: 0.2,
      maxDP: 0.3,
      minAdminFee: 99000,
      maxAdminFee: 199000
    }
  },
  {
    name: "Gadget",
    img: ImgGadget,
    type: CommodityTypes.gadget,
    data: {
      insurance: false,
      minPrice: 825000,
      maxPrice: 14250000,
      specialPrice: 5600000,
      ir: 0.0249,
      tenor: [6, 9],
      minTenor: 6,
      maxTenor: 9,
      minDP: 0.2,
      maxDP: 0.3,
      minAdminFee: 99000,
      maxAdminFee: 199000
    }
  },
  {
    name: "Furnitur",
    img: ImgFurniture,
    type: CommodityTypes.furniture,
    data: {
      insurance: true,
      minPrice: 1000000,
      maxPrice: 11000000,
      ir: 0.0199,
      specialPrice: 5000000,
      tenor: [6, 9],
      minTenor: 6,
      maxTenor: 9,
      minDP: 0.05,
      maxDP: 0.1,
      minAdminFee: 199000,
      maxAdminFee: 199000
    }
  },
  {
    name: "Kamera",
    img: ImgCamera,
    type: CommodityTypes.kamera,
    data: {
      insurance: false,
      minPrice: 1200000,
      maxPrice: 12500000,
      ir: 0.0199,
      specialPrice: null,
      tenor: [6, 9],
      minTenor: 6,
      maxTenor: 9,
      minDP: 0.2,
      maxDP: 0.2,
      minAdminFee: 199000,
      maxAdminFee: 199000
    }
  },
  {
    name: "Televisi",
    img: ImgTelevisi,
    type: CommodityTypes.televisi,
    data: {
      insurance: false,
      minPrice: 1200000,
      maxPrice: 12500000,
      specialPrice: null,
      ir: 0.0229,
      tenor: [6, 9],
      minTenor: 6,
      maxTenor: 9,
      minDP: 0.2,
      maxDP: 0.2,
      minAdminFee: 199000,
      maxAdminFee: 199000
    }
  },
  {
    name: "Komputer atau Laptop",
    img: ImgPCLaptop,
    type: CommodityTypes.komputer_atau_laptop,
    data: {
      insurance: false,
      minPrice: 1200000,
      maxPrice: 12500000,
      specialPrice: null,
      ir: 0.0229,
      tenor: [6, 9],
      minTenor: 6,
      maxTenor: 9,
      minDP: 0.2,
      maxDP: 0.2,
      minAdminFee: 199000,
      maxAdminFee: 199000
    }
  }
];

export const DataCalculator2023 = [
  {
    name: "Harga Barang",
    minPrice: 500000,
    maxPrice: 30000000,
    type: "rupiah",
    errorMsg: "",
    step: 100000,
    tenor: [500000, 30000000],
    handler: "HandlePrice",
    value: "price",
    currency: true,
    inputType: "tel"
  },
  {
    name: "Bunga",
    minPrice: 0,
    maxPrice: 3,
    type: "percentage",
    errorMsg: "",
    step: 0.2,
    tenor: [0, 3],
    handler: "HandleBunga",
    value: "bunga",
    currency: false,
    inputType: "text"
  },
  {
    name: "Tenor (Bulan)",
    minPrice: 3,
    maxPrice: 15,
    minZeroConditional: 6,
    maxZeroConditional: 9,
    type: "month",
    errorMsg: "",
    step: 3,
    tenor: [3, 6, 9, 12, 15],
    tenorZeroConditional: [6, 9],
    handler: "HandleTenor",
    value: "tenor",
    currency: false,
    inputType: "tel"
  },
  {
    name: "Uang Muka (DP)",
    minPrice: 500000,
    maxPrice: 27000000,
    type: "rupiah",
    errorMsg: "",
    step: 100000,
    tenor: [500000, 27000000],
    handler: "HandleDP",
    value: "downPayment",
    currency: true,
    inputType: "tel"
  }
];
